<template>

  <div>

    <div class="w-100 text-center">

      <img v-if="hasExistingImage && !hasImageUploaded" :src="entity.medias[value.id].url.full" class="p-2 img-preview img-preview-existing" />

      <image-uploader
        :id="pluginId"
        :debug="2"
        :maxWidth="1000"
        :maxHeight="250"
        :quality="0.6"
        :autoRotate=true
        outputFormat="base64"
        :preview=true
        :className="['fileinput', { 'fileinput--loaded' : hasImageUploaded }]"
        :capture="false"
        accept="image/*"
        doNotResize="['gif', 'svg']"
        @input="setImage"
      >
        <label :for="pluginId" :class="{'d-block mt-2': true, 'mt-3' : hasImageUploaded }" slot="upload-label">
          <span :class="`btn btn-outline-${classColorName} btn-sm`">
            <i class="icon-image2 mr-2"></i>
            <span class="text-dark">{{ (hasImageUploaded || hasExistingImage) ? $t('modules.process.process.template.addons.image.buttons.replace') : $t('modules.process.process.template.addons.image.buttons.upload') }}</span>
          </span>
        </label>
      </image-uploader>

    </div>

  </div>

</template>

<script>
import {
  PROCESS_ADD_MEDIA_TEMPLATE
} from '../../mutations-types'

export default {
  name: 'process-template-addon-image',
  props: ['value', 'entity', 'classColorName'],
  data () {
    return {
      hasImageUploaded: false,
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
    setImage (base64) {
      this.hasImageUploaded = true

      this.$store.dispatch('process/' + PROCESS_ADD_MEDIA_TEMPLATE, {
        worldId: this.$route.params.world,
        templateId: this.$route.params.template,
        base64: base64,
        replace: this.hasExistingImage ? this.value.id : null,
      }).then(media => {
        this.changeVal({
          'id': media.id,
        })
      })
      .catch(error => {
        this.notifError(error)
      })

    },
  },
  computed: {
    hasExistingImage () {
      return this.value && this.value.id && this.value.id != null
    },
    pluginId () {
      return this.hasExistingImage ? 'existing-' + this.value.id : 'new-' + Math.floor(Math.random()*100000000)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .fileinput,
  #fileInput {
    display: none;
  }
  .img-preview {
    max-height: none;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
</style>
