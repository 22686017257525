<template>

  <div>
    <div class="editor mt-1 w-100 p-1 pb-2">
      <editor-floating-menu :editor="editor" v-slot="{ commands, isActive, menu }">
        <div
          class="editor__floating-menu"
          :class="{ 'is-active': menu.isActive }"
          :style="`top: ${menu.top}px`"
        >

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            <span class="text">H<sup>2</sup></span>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            <span class="text">H<sup>3</sup></span>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <i class="icon-list-unordered"></i>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <i class="icon-list-ordered"></i>
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <i class="icon-quotes-left"></i>
          </button>

        </div>
      </editor-floating-menu>

      <editor-menu-bubble :editor="editor" :keep-in-bounds="true" v-slot="{ commands, isActive, menu }">
        <div
          class="menububble"
          :class="{ 'is-active': menu.isActive }"
          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
        >

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <i class="icon-bold2"></i>
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <i class="icon-italic2"></i>
          </button>

          <button
            v-if="!isActive.bullet_list() && !isActive.ordered_list()"
            class="menububble__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            <span class="text">H<sup>2</sup></span>
          </button>

          <button
            v-if="!isActive.bullet_list() && !isActive.ordered_list()"
            class="menububble__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            <span class="text">H<sup>3</sup></span>
          </button>

          <button
            v-if="!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 })"
            class="menububble__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <i class="icon-list-unordered"></i>
          </button>

          <button
            v-if="!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 })"
            class="menububble__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <i class="icon-list-ordered"></i>
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <i class="icon-quotes-left"></i>
          </button>


        </div>
      </editor-menu-bubble>

      <editor-content class="editor__content" :editor="editor"/>
    </div>
  </div>

</template>

<script>


import {
  Editor,
  EditorContent,
  EditorFloatingMenu,
  EditorMenuBubble,
} from 'tiptap'
import {
  Blockquote,
  BulletList,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Underline,
  History,
  TrailingNode,
  Placeholder,
} from 'tiptap-extensions'

export default {
  name: 'process-template-addon-editor',
  props: ['value', 'classColorName'],
  components: {
    EditorContent,
    EditorFloatingMenu,
    EditorMenuBubble,
  },
  data () {
    return {
      editor: null,
    }
  },
  mounted () {

    this.editor = new Editor({
      editable: !this.isContentLocked,
      extensions: [
        new Blockquote(),
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [2, 3] }),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Italic(),
        new History(),
        new TrailingNode({
          node: 'paragraph',
          notAfter: ['paragraph'],
        }),
        new Underline(),
        new Placeholder({
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.$t('modules.process.process.template.addons.editor.placeholder'),
          showOnlyWhenEditable: true,
        }),
      ],
      content: this.value,
      onBlur: () => {
        this.changeVal(this.editor.getHTML().trim())
      },
      // tell ProseMirror to ignore drop event
      editorProps: { handleDOMEvents: {
        drop: (view, e) => { e.preventDefault(); },
      } },
      // hide the drop position indicator
      dropCursor: { width: 0, color: 'transparent' },
      onFocus: () => {
      }
    })

  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
  },
  watch: {
    value(val) {
      this.$forceUpdate()
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .editor__content {

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
